import React from 'react';
import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const NavButton = styled((props) => {
    const defaultProps = {
        variant: "text",
        component: Link
    };
    props = {...defaultProps, ...props};
    return (
        <Button {...props} />
    );
})
(({ theme }) => ({
    color: theme.palette.primary.contrastText
}));

export default NavButton;