import { Outlet } from "react-router-dom";
import PageContainer from "../base/layout/PageContainer";
import {Grid} from "@mui/material";

const RawLayout = () => {
    return (
        <PageContainer>
            <Grid container justifyContent="center" spacing={1}>
                <Outlet/>
            </Grid>
        </PageContainer>
    );
};

export default RawLayout;