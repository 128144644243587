import React from 'react';
import { styled, Typography } from '@mui/material';

const HeadingText = styled((props) => {

    let level = props.level ?? 1;
    if(level > 6) { level = 6; }
    if(level < 1) { level = 1; }

    const defaultProps = {
        component: `h${level}`,
        variant: `h${level}`,
        gutterBottom: true,
    }

    props = { ...defaultProps, ...props };
    // use sx instead of style.
    if(props.style) {
        props.sx = props.style;
        props.style = undefined;
    }

    return (
        <Typography {...props} />
    );
})(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3),
}));

export default HeadingText;
