import React, {useEffect, useState} from 'react';
import {InputAdornment, IconButton, styled} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TextInput from "../input/TextInput";

const SearchBox = styled(({onSearch, value, ...props}) => {
    const [searchTerm, setSearchTerm] = useState(value);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const emitSearchEvent = (event, term) => {
        event.searchTerm = term;
        onSearch(event);
    }

    const handleSearch = (event) => {
        emitSearchEvent(event, searchTerm);
    }

    const handleClearSearch = (event) => {
        setSearchTerm('');
        emitSearchEvent(event, '');
    }

    const handleKeyUp = (event) => {
        if (event.key === "Enter") {
            emitSearchEvent(event, searchTerm);
        }
    }

    useEffect(() => {
        setSearchTerm(value);
    }, [value]);

    return (
        <TextInput
            label={"Search"}
            placeholder="Search..."
            value={searchTerm ?? ''}
            onChange={handleInputChange}
            onKeyUp={handleKeyUp}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {!!searchTerm &&
                            <IconButton onClick={handleClearSearch}>
                                <CloseIcon />
                            </IconButton>
                        }
                        <IconButton onClick={handleSearch}>
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...props}
        />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
    margin: theme.spacing(0, 2)
}));

export default SearchBox;