import {styled, Typography} from '@mui/material';
import React from "react";

const TextIcon = styled(
    ({color, ...props}) => {
        const defaultProps = {
            color: 'primary',
            borderColor: 'primary',
        };
        props = {...defaultProps, ...props};

        return (
            <Typography
                sx={{
                    color: color,
                    borderColor: color,
                    ...(props.sx || {}),
                }}
                {...props}
            />
        );
    }
)(({ theme }) => ({
    display: 'inline-block',
    borderRadius: '80%',
    borderWidth: theme.spacing(.5),
    borderStyle: "solid",
    padding: theme.spacing(.5),
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold
}));
export default TextIcon;