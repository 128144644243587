import React from 'react';
import {Pagination as MuiPagination, styled} from '@mui/material';

const Pagination = styled((props) => {
    const defaultProps = {
        count: 0,
        page: 1,
        size: "small",
        variant: "outlined",
        shape: "rounded",
        siblingCount: 1,
        boundaryCount: 2,
        showFirstButton: true,
        showLastButton: true,
    };
    props = { ...defaultProps, ...props };

    return (
        <MuiPagination {...props} />
    );
})(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default Pagination;