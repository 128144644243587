import React, {useState} from 'react';
import SubmitButton from "../base/button/SubmitButton";
import TextInput from "../base/input/TextInput";
import {useFormik} from "formik";
import {Grid} from "@mui/material";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {useApiContext} from "../base/context/ApiContext";
import {AppPath} from "../AppRouter";
import Paragraph from "../base/typography/Paragraph";
import SubtleButton from "../base/button/SubtleButton";

const RequestPasswordResetForm = () => {
    const {api, callApi} = useApiContext();
    let navigate = useNavigate();

    const  defaultMessage ="Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.";
    const [resetSent, setResetSent] = useState(false);
    const [resetResponse, setResetResponse] = useState(null);

    const goToLogin = () => {
        navigate(AppPath.Login);
    }

    const handleSubmit = async (values) => {
        const response = await callApi(api.sendPasswordResetEmail, values.username);
        setResetSent(true);
        setResetResponse(response);
    };

    const initialValues = {username: ''};

    const validationSchema = Yup.object({
        username: Yup.string()
            .email('Use your email address')
            .required('Required')
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                await handleSubmit(values);
            } catch (e) {
                console.error(e);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            {
                resetSent
                    ? <Grid container justifyContent={"center"}>
                        <Paragraph>{resetResponse}</Paragraph>
                        <SubtleButton onClick={goToLogin}>Continue to log in</SubtleButton>
                    </Grid>
                    : <form onSubmit={formik.handleSubmit}>
                        <Grid container>
                            <Paragraph>
                                Enter your user account's verified email address and we will send you a password reset link.
                            </Paragraph>
                        </Grid>
                        <Grid container>
                            <TextInput
                                id="username"
                                name="username"
                                label="Email"
                                autoComplete={'username email'}
                                formik={formik}
                            />
                        </Grid>
                        <Grid container justifyContent={"space-between"}>
                            <SubmitButton disabled={formik.isSubmitting}>Send password reset email</SubmitButton>
                            <SubtleButton onClick={goToLogin}>Cancel</SubtleButton>
                        </Grid>
                    </form>
            }

        </>
    );
}
export default RequestPasswordResetForm;
