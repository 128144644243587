import Api from "../base/service/Api";

class Repo extends Api {
    static CACHE_TIME_SEC = {
        FRESH: 60, // 1 min
        SPAM_PREVENTION: 300, // 5 min
        STATIC_DATA: 86400, // 1 day
    }

    constructor(baseUrl, cacheDriver = Api.CACHE_DRIVER.AUTO) {
        super(baseUrl, cacheDriver);
    }

    createLoginRequest(username, password) {
        return {
            url: '/login',
            method: 'post',
            data: {
                username: username,
                password: password
            }
        };
    }

    async login(username, password) {
        const request = this.createLoginRequest(username, password);
        const response = await this.executeNoCacheRequest(request);
        // response also contains jwt token information, that is also set in cookies for us,
        // so we can just pass along the userContext portion of the response.
        return response.data.userContext;
    }

    createLogoutRequest() {
        return {
            url: '/logout',
            method: 'post'
        };
    }
    async logout() {
        const request = this.createLogoutRequest();
        const response = await this.executeNoCacheRequest(request);
        return response.data;
    }

    createGetShipmentHistoryRequest(pageIndex, pageSize, searchTerm, sort) {
        const request = {
            url: '/shipments',
            method: 'get',
            ttl: Repo.CACHE_TIME_SEC.FRESH,
            params: {
                $skip: (pageIndex * pageSize),
                $top: pageSize,
                $orderby: sort,
                searchTerm: searchTerm
            }
        };

        if(!request.params.$orderby || !request.params.$orderby.length) {
            delete request.params.$orderby;
        }

        if(!request.params.searchTerm || !request.params.searchTerm.length) {
            delete request.params.searchTerm;
        }

        return request;
    }

    createSendPasswordResetEmailRequest(email) {
        return {
            url: '/password-reset',
            method: 'put',
            ttl: Repo.CACHE_TIME_SEC.SPAM_PREVENTION,
            data: {
                email: email
            }
        };
    }

    async sendPasswordResetEmail(email) {
        const request = this.createSendPasswordResetEmailRequest(email);
        const response = await this.executeNoCacheRequest(request);
        return response.data.message;
    }

    createTestInvitationTokenRequest(userId, token) {
        return {
            url: `/invitation/${token}/${userId}`,
            method: 'get',
            ttl: Repo.CACHE_TIME_SEC.SPAM_PREVENTION
        };
    }
    async testInvitationToken(userId, token) {
        const request = this.createTestInvitationTokenRequest(userId, token);
        try{
            await this.executeRequest(request);
            return true;
        } catch (err) {
            if(err.status === 403) {
                err.message = 'This password reset link is invalid.';
            }
            throw err;
        }
    }

    createResetForgottenPasswordRequest(userId, token, newPassword) {
        return {
            url: `/reset/${userId}`,
            method: 'post',
            ttl: Repo.CACHE_TIME_SEC.SPAM_PREVENTION,
            data: {
                key: token,
                password: newPassword
            }
        };
    }
    async resetForgottenPassword(userId, token, newPassword) {
        const request = this.createResetForgottenPasswordRequest(userId, token, newPassword);
        const response = await this.executeRequest(request);
    }

    async getShipmentHistory(pageIndex, pageSize, searchTerm, sort) {
        const request = this.createGetShipmentHistoryRequest(pageIndex, pageSize, searchTerm, sort);
        const response = await this.executeRequest(request);
        return response.data;
    }

    async throwError(statusCode) {
        let path = '';
        switch (statusCode) {
            case 400:
                path = "error/domain";
                break;
            case 401:
                path = "error/auth";
                break;
            case 403:
                path = "error/forbidden";
                break;
            case 404:
                path = "error/not-found";
                break;
            case 422:
                path = "error/invalid";
                break;
            case 503:
                path = "error/unavailable";
                break;
            case 500:
                path = "/error/server-with-message";
                break;
            default:
                path = "error/server-no-message";
                break;
        }
        const request = {
            url: path,
            method: 'get'
        };

        const response = await this.executeNoCacheRequest(request);
        return response.data;
    }
}
export default Repo;