import { experimental_extendTheme as extendTheme} from '@mui/material/styles';

const themeOptions = {
    cssVarPrefix: 'tgf-cf',
    spacing: 8, // Default spacing value is 8
    palette: {
        mode: 'light',
        primary: {
            main: '#708C37',
        },
        secondary: {
            main: '#8c7e37',
        },
    },
    typography: {
        h1: {
            fontSize: '3.0rem',
        },
        h2: {
            fontSize: '2.50rwm',
        },
        h3: {
            fontSize: '2.0rem',
        },
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {

                }
            }
        },
    }
};

const DefaultTheme = extendTheme(themeOptions);
export default DefaultTheme;
