import React from 'react';
import { Button, styled } from '@mui/material';

const SubmitButton = styled((props) => {
    const defaultProps = {
        type: "submit",
        variant: "contained",
        color: "primary",
        label: "Submit"
    };
    props = {...defaultProps, ...props};

    return (
        <Button {...props} />
    );
})
(({ theme }) => ({
    margin: theme.spacing(1,0)
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default SubmitButton;