import {Alert, Collapse, IconButton, styled} from "@mui/material";
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

const ErrorAlert = styled((props) => {
    const [open, setOpen] = useState(true);

    const defaultProps = {
        severity: 'error'
    };
    props = {...defaultProps, ...props};
    return (
        <Collapse in={open}>
            <Alert
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                {...props}
            />
        </Collapse>
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));
export default ErrorAlert;