import React from "react";
import { useUserContext } from "../base/context/UserContext";
import {Grid} from "@mui/material";
import HeadingText from "../base/typography/HeadingText";
import Paragraph from "../base/typography/Paragraph";
import DataValue from "../base/typography/DataValue";
import DataLabel from "../base/typography/DataLabel";
import InlineText from "../base/typography/InlineText";
import ErrorText from "../base/typography/ErrorText";
import JsonDisplay from "../base/composite/JsonDisplay";

const About = () => {
    const { userContext } = useUserContext();

    return (
        <Grid container>
            <Grid container>
                <HeadingText width={"100%"} level={1}>About Page</HeadingText>
            </Grid>
            <Grid container>
                <Paragraph>
                    <DataLabel>Id:</DataLabel>&nbsp;<DataValue>{userContext?.id}</DataValue><br/>
                    <DataLabel>Email:</DataLabel>&nbsp;<DataValue>{userContext?.email}</DataValue><br/>
                    <DataLabel>Disabled:</DataLabel>&nbsp;<DataValue>{userContext?.isDisabled}</DataValue><br/>
                    <br/>
                    <InlineText>This is some <mark>Highlighted</mark> Text.</InlineText>
                    <InlineText fontWeight={"bold"} fontStyle={"italic"} sx={{textDecoration: 'green wavy underline overline'}}>
                        Just some Text
                    </InlineText><br/>
                    <br/>
                    <ErrorText style={{textDecoration: 'underline'}}>This is some Error Text</ErrorText><br/>
                </Paragraph>
            </Grid>
            <Grid container>
                <JsonDisplay value={userContext} keyName={'userContext'} />
            </Grid>
        </Grid>
    );
};

export default About;
