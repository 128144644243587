import React from 'react';
import { styled } from '@mui/material';
import InlineText from "./InlineText";

const ErrorText = styled((props) => {

    const defaultProps = {
        fontWeight: 'bold',
        color: 'error',
    };

    props = { ...defaultProps, ...props };

    return (
        <InlineText {...props} />
    );
})(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default ErrorText;