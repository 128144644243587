import {useApiContext} from "../base/context/ApiContext";
import HeadingText from "../base/typography/HeadingText";
import React, {useEffect, useState} from "react";
import {CardContent, Grid} from "@mui/material";
import SearchBox from "../base/composite/SearchBox";
import PagedRepeater from "../base/layout/PagedRepeater";
import Card from "../base/layout/Card";
import TextHighlighter from "../base/typography/TextHighlighter";
import DataLabel from "../base/typography/DataLabel";
import DataValue from "../base/typography/DataValue";
import CurrencyValue from "../base/typography/CurrencyValue";
import usePageCachedState from "../base/context/usePageCachedState";
import {AppPath} from "../AppRouter";
import TextIcon from "../base/button/TextIcon";
import {amber, lightBlue} from "@mui/material/colors";

const ShipmentHistory = () => {
    const {api, callApi} = useApiContext();

    const getPageOfShipments = async (index, size, term, sort) => {
        const shipments = await callApi(api.getShipmentHistory, index, size, term, sort);
        return shipments;
    };

    const [searchTerm, setSearchTerm] = usePageCachedState(`${AppPath.ShipmentHistory}.searchTerm`, '');
    const [pageInfo, setPageInfo] = usePageCachedState(`${AppPath.ShipmentHistory}.pageInfo`, {index: 0, size: 10});
    //const [searchTerm, setSearchTerm] = useState('');
    //const [pageInfo, setPageInfo] = useState({index: 0, size: 1});
    const [dataPage, setDataPage] = useState();

    const handlePageInfoChanged = async (pageNumber, pageSize) => {
        let pageIndex = pageNumber - 1;
        if (dataPage && pageIndex * pageSize >= dataPage.totalItemCount) {
            pageIndex = Math.floor(dataPage.totalItemCount / pageSize);
        }
        await setPageInfo({index: pageIndex, size: pageSize});
    };

    const handleSearch = async (event) => {
        await setPageInfo({index: 0, size: pageInfo.size});
        await setSearchTerm(event.searchTerm);
    };

    useEffect(() => {
        const fetchData = async () => {
            const shipments = await getPageOfShipments(pageInfo.index, pageInfo.size, searchTerm, null);
            setDataPage(shipments);
        };
        if(pageInfo) {fetchData();}
    }, [pageInfo, searchTerm]);

    return (
        <Grid container>
            <Grid container>
                <HeadingText width={"100%"} level={1}>Shipment History</HeadingText>
            </Grid>
            <Grid container>
                <SearchBox placeholder={"Search by BOL Number, BOL Month/Year, Status, Location, or Equipment..."} value={searchTerm} onSearch={handleSearch} />
                <PagedRepeater dataPage={dataPage} onPageInfoChange={handlePageInfoChanged} pageSizes={[1,2,5,10,20,50]}>
                    {(item) => (
                        <Card key={item.bolNumber}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={2} sm={1}>
                                            {item.freightCategory === "TL" && <TextIcon color={lightBlue[500]}>&nbsp;TL&nbsp;</TextIcon>}
                                            {item.freightCategory === "LTL" && <TextIcon color={amber[600]}>LTL</TextIcon>}
                                        </Grid>
                                        <Grid item xs={10} sm={5}>
                                            <DataLabel>BOL:</DataLabel>
                                            <TextHighlighter searchWords={[searchTerm]} textToHighlight={item.bolNumber.toString()} /><br/>

                                            <DataLabel>BOL Date:</DataLabel><DataValue>{new Date(item.bolDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            })}</DataValue>
                                        </Grid>
                                        <Grid item xs={10} sm={5}>
                                            <DataLabel>Shipment Status:</DataLabel>
                                            <TextHighlighter searchWords={[searchTerm]} textToHighlight={item.shipmentStatus} /><br/>
                                            <DataLabel>Delivery Date:</DataLabel>
                                            <DataValue>{new Date(item.deliveryDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            })}</DataValue><br />
                                            <DataLabel>Remaining Balance:</DataLabel><CurrencyValue>{item.remainingBalance}</CurrencyValue><br/>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={2} md={1}>
                                            {/*<img width="24" height="24"*/}
                                            {/*     src="https://img.icons8.com/emoji/48/warning-emoji.png"*/}
                                            {/*     alt="hazmat"/>*/}
                                            {/*<img width="24" height="24"*/}
                                            {/*     src="https://img.icons8.com/material/48/visualy-impaired.png"*/}
                                            {/*     alt="blind"/>*/}
                                            {!!item.isHazmat && <img width="24" height="24"
                                                                     src="https://img.icons8.com/emoji/48/warning-emoji.png"
                                                                     alt="hazmat"/>}
                                            {!!item.isBlind && <img width="24" height="24"
                                                                    src="https://img.icons8.com/material/48/visualy-impaired.png"
                                                                    alt="blind"/>}
                                        </Grid>
                                        <Grid item xs={10} md={11}>
                                            <DataLabel>Equipment Type:</DataLabel><TextHighlighter searchWords={[searchTerm]} textToHighlight={item.equipmentType} />
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            &nbsp;
                                        </Grid>
                                        <Grid item xs={5}>
                                            <DataLabel>Origin:</DataLabel><TextHighlighter searchWords={[searchTerm]} textToHighlight={item.originName}/><br />
                                            <TextHighlighter searchWords={[searchTerm]} textToHighlight={`${item.originCity}, ${item.originState}`} />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <DataLabel>Destination:</DataLabel><TextHighlighter searchWords={[searchTerm]} textToHighlight={item.destinationName}/><br />
                                            <TextHighlighter searchWords={[searchTerm]} textToHighlight={`${item.destinationCity}, ${item.destinationState}`} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*<JsonDisplay value={item} keyName={'shipment'} collapsed={true} />*/}
                            </CardContent>
                        </Card>
                    )}
                </PagedRepeater>
            </Grid>
        </Grid>
    );
};

export default ShipmentHistory;
