import React, {createContext, useContext, useEffect, useState} from 'react';

const UserContext = createContext(null);

export const UserContextProvider = ( {children} ) => {

    const [userContext, setUserContext] = useState(() => {
        const storedUserContext = localStorage.getItem('userContext');
        return storedUserContext ? JSON.parse(atob(storedUserContext)) : null;
    });

    const login = (userData) => {
        if(userData) {
            const encodedUserData = btoa(JSON.stringify(userData));
            setUserContext(userData);
            localStorage.setItem('userContext', encodedUserData);
        }
    };

    const logout = () => {
        setUserContext(null);
        localStorage.removeItem('userContext');
    };

    useEffect(() => {
        const storedUserContext = localStorage.getItem('userContext');
        if (storedUserContext) {
            setUserContext(JSON.parse(atob(storedUserContext)));
        }
    }, []);

    return (
        <UserContext.Provider value={{ userContext, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a <UserContext.Provider>');
    }
    return context;
};