import React from 'react';
import { styled, Typography } from '@mui/material';

const Paragraph = styled((props) => {

    const defaultProps = {
        variant: 'body1',
        paragraph: true,
        gutterBottom: true
    }

    props = { ...defaultProps, ...props };
    // use sx instead of style.
    if(props.style) {
        props.sx = props.style;
        props.style = undefined;
    }

    return (
        <Typography {...props} />
    );
})(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default Paragraph;