import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import NavButton from "../base/button/NavButton";
import {AppPath} from "../AppRouter";

const Header = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <NavButton to={AppPath.Home}>Home</NavButton>
                <NavButton to={AppPath.About}>About</NavButton>
                <NavButton to={AppPath.ShipmentHistory}>Shipment History</NavButton>
                <NavButton to={AppPath.Logout}>Logout</NavButton>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
