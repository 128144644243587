import React from 'react';
import { Container, styled } from '@mui/material';

const PageContainer = styled((props) => {
    const defaultProps = {
        maxWidth: "lg"
    };
    props = { ...defaultProps, ...props };

    return (
        <Container {...props} />
    );
})(({ theme }) => ({
    margin: theme.spacing(0, 'auto'), // Center align the container
    padding: theme.spacing(2)
}));

export default PageContainer;