import { styled } from "@mui/material";

const WindowOverlay = styled('div')
(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: (theme.zIndex.tooltip + 10),
}));

export default WindowOverlay;