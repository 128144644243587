import { HelmetProvider } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import DefaultTheme from "./theme/DefaultTheme";
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import Repo from "./service/Repo";
import {ApiProvider} from "./base/context/ApiContext";
import {UserContextProvider} from "./base/context/UserContext";
import AppRouter from "./AppRouter";
import Config from "./Config";
import PageCache from "./base/service/PageCache";
import {PageCacheProvider} from "./base/context/PageCacheContext";
import {useEffect, useMemo} from "react";

function App({deps}) {
    if(Config.env !== 'production') {
        console.log(`This page is using a ${Config.env} build of React. See how this interacts with <React.StrictMode> here. https://legacy.reactjs.org/docs/strict-mode.html`)
        console.log(JSON.stringify(Config));
    }

    const api = useMemo(() => deps?.api ?? new Repo(Config.apiBaseUrl, Config.apiCacheDriver), [deps]);
    const pageCache = useMemo(() => deps?.pageCache ?? new PageCache(Config.host, Config.pageCacheDriver), [deps]);

    useEffect(() => {
        const f = async () => {await api.applyCacheVersion(Config.apiCacheVersion);}
        f();
    }, [api]);

    useEffect(() => {
        const f = async () => {await pageCache.applyCacheVersion(Config.pageCacheVersion);}
        f();
    }, [pageCache]);

    return (
        <HelmetProvider>
            <ApiProvider apiInstance={api}>
                <PageCacheProvider cacheInstance={pageCache}>
                    <CssVarsProvider theme={DefaultTheme}>
                        <CssBaseline />
                        <UserContextProvider>
                            <AppRouter />
                        </UserContextProvider>
                    </CssVarsProvider>
                </PageCacheProvider>
            </ApiProvider>
        </HelmetProvider>
    );
}
export default App;
