import React from 'react';
import {Box, FormControl, Grid, MenuItem, Select, styled} from '@mui/material';
import Pagination from "./Pagination";
import InlineText from "../typography/InlineText";

const PagedRepeater = styled(({
                                  dataPage,
                                  setDataPage,
                                  pageSizes,
                                  onPageInfoChange,
                                  children,
                                  paginationProps = {},
                                  disablePagination = false,
                                  disablePageSizes = false,
                                  ...props }) => {

    const onPageSizeChange = (event) => {
        if (onPageInfoChange) {
            onPageInfoChange(dataPage.pageIndex + 1, event.target.value);
        }
    }

    const onPageChange = (event, newPage) => {
        if (onPageInfoChange) {
            onPageInfoChange(newPage, dataPage.pageSize);
        }
    }

    if(!disablePagination && !pageSizes) {
        pageSizes = [5,10,20,50];
    }

    return (
        <>
            {dataPage && dataPage.items && dataPage.items.length ? (
                <Grid container justifyContent={"space-between"}>

                    {dataPage.items && dataPage.items.map(item => children(item))}
                    {!disablePagination && (dataPage.totalItemCount > dataPage.items.length) &&
                        <Pagination
                            count={dataPage.totalPageCount}
                            page={dataPage.pageIndex + 1}
                            onChange={onPageChange}
                            {...paginationProps}
                        />
                    }
                    <InlineText size={"small"}>{dataPage.totalItemCount} Items</InlineText>
                    {(!disablePageSizes) && pageSizes &&
                        <FormControl size="small">
                            <Select value={dataPage.pageSize} onChange={onPageSizeChange}>
                                {pageSizes.map(size => (
                                    <MenuItem key={size} value={size}>{size} per page</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                </Grid>
            ) : (
                <p>No items available</p>
            )}
        </>
    );
})(({ theme }) => ({

}));

export default PagedRepeater;