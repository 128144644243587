import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../composite/Header";
import {Grid} from "@mui/material";
import PageContainer from "../base/layout/PageContainer";

const NavLayout = () => {
    return (
        <PageContainer>
            <Grid container direction="column" spacing={1}>
                <Grid container direction="row">
                    <Header/>
                    <Outlet/>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default NavLayout;
