import React from 'react';
import {Grid} from "@mui/material";
import RequestPasswordResetForm from "../form/RequestPasswordResetForm";
import HeadingText from "../base/typography/HeadingText";

const RequestPasswordReset = (props) => {
    return (
        <Grid container item xs={12} sm={10} md={8} lg={6}>
            <HeadingText level={1} width={"100%"} textAlign={"center"}>Reset Password</HeadingText>
            <RequestPasswordResetForm/>
        </Grid>
    );
};
export default RequestPasswordReset;
