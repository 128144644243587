import React from 'react';
import { styled } from '@mui/material';
import Highlighter  from "react-highlight-words";

const TextHighlighter = styled((props) => {
    const defaultProps = {
        highlightTag: 'mark',
        searchWords: [],
        textToHighlight: ''
    };

    props = {...defaultProps, ...props};

    return (
        <Highlighter {...props} />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default TextHighlighter;