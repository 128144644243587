import {AppPath} from './AppRouter';
import Api from "./base/service/Api";
import PageCache from "./base/service/PageCache";

const Config = {
    env: process.env.NODE_ENV ?? 'production',
    version: process.env.REACT_APP_VERSION ?? null,
    host: `${window.location.protocol}//${window.location.host}`,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL
        ? `${process.env.REACT_APP_API_BASE_URL.replace(/\/+$/, '')}${AppPath.ApiRoot}`
        : `${window.location.protocol}//${window.location.host}${AppPath.ApiRoot}`,
    apiCacheDriver: process.env.REACT_APP_API_CACHE_DRIVER ?? Api.CACHE_DRIVER.AUTO,
    apiCacheVersion: process.env.REACT_APP_API_CACHE_VERSION ?? null,
    pageCacheDriver: process.env.REACT_APP_PAGE_CACHE_DRIVER ?? PageCache.CACHE_DRIVER.AUTO,
    pageCacheVersion: process.env.REACT_APP_PAGE_CACHE_VERSION ?? null,
    timezone:  Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezoneOffset: (new Date()).getTimezoneOffset() / 60,
};
export default Config;