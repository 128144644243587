import HeadingText from "../base/typography/HeadingText";
import React from "react";

const Error = () => {
    return (
        <HeadingText width={"100%"} level={1}>Error Page</HeadingText>
    );
};

export default Error;
