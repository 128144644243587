import React from 'react';
import { Button, styled } from '@mui/material';

const ActionButton = styled((props) => {
    const defaultProps = {
        variant: "contained",
        color: "primary"
    };
    props = {...defaultProps, ...props};
    return (
        <Button {...props} />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default ActionButton;