import { Navigate, Outlet } from 'react-router-dom'
import { useUserContext } from './UserContext';

const ProtectedRoutes = ({redirectUrl, isLoggedInFunc}) => {
    const { userContext } = useUserContext();

    const isLoggedIn = (isLoggedInFunc && isLoggedInFunc instanceof Function)
        ? isLoggedInFunc(userContext)
        : userContext && userContext.id;

    return (
        isLoggedIn ? <Outlet/> : <Navigate to={redirectUrl ?? '/login'}/>
    )
}

export default ProtectedRoutes;