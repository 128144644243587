import React from 'react';
import { styled } from '@mui/material';
import InlineText from "./InlineText";

const DataValue = styled(({ children, ...props }) => {
    const defaultProps = {};
    props = { ...defaultProps, ...props };

    if(children != null) {
        if(children instanceof Object || Array.isArray(children)) {
            children = JSON.stringify(children);
        } else {
            children = children.toString();
        }
    }

    return <InlineText {...props}>{children}</InlineText>; })
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
    toString() { return this.props.children.toString(); }
}));

    export default DataValue;