import React from 'react';
import {Grid} from "@mui/material";
import LoginForm from "../form/LoginForm";
import HeadingText from "../base/typography/HeadingText";

const Login = (props) => {
    return (
        <Grid container item xs={12} sm={8} md={6} lg={4} xl={4}>
            <Grid container>
                <HeadingText level={1} width={"100%"} textAlign={"center"}>Login Page</HeadingText>
            </Grid>
            <LoginForm/>
        </Grid>
    );
};
export default Login;
