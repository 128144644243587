import React, {createContext, useCallback, useContext, useState} from 'react';
import LoadingOverlay from "../alert/LoadingOverlay";
import ErrorAlert from "../alert/ErrorAlert";
import HeadingText from "../typography/HeadingText";
import DataLabel from "../typography/DataLabel";
import ErrorText from "../typography/ErrorText";

const ApiContext = createContext(null);

export const ApiProvider = ({ children, apiInstance }) => {
    const [api, setApi] = useState(apiInstance);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const callApi = useCallback(async (apiFunction, ...args) => {
        setLoading(true);
        setError(null);

        try {
            // use bind so `this` works inside the api class.
            const result = await apiFunction.bind(api)(...args);
            return result;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [api]);

    const setApiInstance = (apiInstance) => {
        setApi(apiInstance);
    }

    return (
        <ApiContext.Provider value={{ api, callApi, setApiInstance }}>
            {loading && <LoadingOverlay />}
            {error && <ErrorAlert>
                {error.message ?? 'An error occurred.'}<br/>
                {error.errors &&
                    <>
                        {Object.keys(error.errors).map((field) => {
                            return (
                                <div key={field}>
                                    <DataLabel>{field}:</DataLabel>
                                    {
                                        Array.isArray(error.errors[field])
                                        ? (
                                            error.errors[field].map((error, index) => (
                                                <><ErrorText>{error}</ErrorText><br/></>
                                            ))
                                        )
                                        : <><ErrorText>{error.errors[field]}</ErrorText><br/></>
                                    }
                                </div>
                            );
                        })}
                    </>
                }
            </ErrorAlert>}
            {children}
        </ApiContext.Provider>
    );
};

export const useApiContext = () => {
    const apiContext = useContext(ApiContext);
    if (!apiContext) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return apiContext;
};

