class ApiException extends Error {
    constructor(errorResponse) {
        super('An API error occurred');

        if (errorResponse.response && errorResponse.response.data) {
            const { status, data } = errorResponse.response;
            let message = 'An error occurred';
            let errors = null;

            switch (status) {
                case "ERR_NETWORK":

                case 400:
                    message = data?.error ?? 'Bad Request - The request is invalid';
                    break;
                case 401:
                    message = data?.error ?? 'Unauthorized - Authentication is required';
                    break;
                case 403:
                    message = data?.error ?? 'Forbidden - Access is forbidden';
                    break;
                case 404:
                    message = data?.error ?? 'Not Found - The resource was not found';
                    break;
                case 422:
                    message = 'Data Validation Error';
                    errors = data?.errors;
                    break;
                case 503:
                    message = data?.error ?? 'Service Unavailable - A required service is currently unavailable.';
                    break;
                case 500:
                    message = data?.error ?? 'Internal Server Error - An unexpected error occurred';
                    break;
                default:
                    message = data?.error ?? message;
            }

            this.message = message;
            this.status = status;
            this.errors = errors;
        } else {
            if(errorResponse.code === "ERR_NETWORK") {
                this.status = 502;
                this.message = "The API Service is currently unreachable."
            } else {
                this.status = 500;
                this.message = 'An error occurred.';
            }

            this.errors = null;
        }
    }
}

export default ApiException;