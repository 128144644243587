import {useEffect, useState} from 'react';
import { usePageCacheContext } from './PageCacheContext';

const usePageCachedState = (key, initialValue) => {
    const { pageCache } = usePageCacheContext();
    const [state, setState] = useState();

    useEffect(() => {
        const loadCachedValue = async () => {
            const cachedValue = await pageCache.store.getItem(key);
            if (cachedValue != null) {
                setState(cachedValue);
            } else {
                await pageCache.store.setItem(key, initialValue);
                setState(initialValue);
            }
        };
        loadCachedValue();
    }, []);

    const setValue = async (value) => {
        await pageCache.store.setItem(key, value);
        setState(value);
    };

    return [state, setValue];
};

export default usePageCachedState;