import React, {createContext, useContext, useState} from 'react';

const PageCacheContext = createContext(null);

const PageCacheProvider = ({ children, cacheInstance }) => {
    const [pageCache, setPageCache] = useState(cacheInstance);

    const setPageCacheInstance = (instance) => {
        setPageCache(instance);
    }

    return (
        <PageCacheContext.Provider value={{pageCache, setPageCacheInstance}}>
            {children}
        </PageCacheContext.Provider>
    );
};

const usePageCacheContext = () => {
    const pageCacheContext = useContext(PageCacheContext);
    if (!pageCacheContext) {
        throw new Error('usePageCache must be used within an PageCacheProvider');
    }
    return pageCacheContext;
};

export { PageCacheProvider, usePageCacheContext };