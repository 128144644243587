import {styled} from "@mui/material";
import JsonView from "@uiw/react-json-view";

const JsonDisplay = styled((props) => {
    const defaultProps = {
        value: {},
        // Define the root node name. @default undefined
        keyName: undefined,
        // Whether sort keys through `String.prototype.localeCompare()`
        objectSortKeys: false,
        // highlight updates to json data.
        highlightUpdates: true,
        // Set the indent-width for nested objects @default 15
        indentWidth: 15,
        // When set to `true`, `objects` and `arrays` are labeled with size @default true
        displayObjectSize: true,
        // When set to `true`, data type labels prefix values @default true
        displayDataTypes: false,
        // The user can copy objects and arrays to clipboard by clicking on the clipboard icon. @default true
        enableClipboard: true,
        // When set to true, all nodes will be collapsed by default. Use an integer value to collapse at a particular depth. @default false
        collapsed: false,
        /** Shorten long JSON strings, Set to `0` to disable this feature @default 30 */
        shortenTextAfterLength: 0
    };

    props = {...defaultProps, ...props};

    return (
        <JsonView {...props} />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
    "--w-rjv-color": theme.palette.primary.main,
    "--w-rjv-key-number": theme.palette.primary.main,
    "--w-rjv-key-string": theme.palette.primary.main,
    "--w-rjv-background-color": theme.palette.background.default,
    "--w-rjv-line-color": theme.palette.grey[700],
    "--w-rjv-arrow-color": theme.palette.text.secondary,
    "--w-rjv-edit-color": theme.palette.primary.main,
    "--w-rjv-info-color": theme.palette.text.disabled,
    "--w-rjv-update-color": theme.palette.primary.main,
    "--w-rjv-copied-color": theme.palette.primary.main,
    "--w-rjv-copied-success-color": theme.palette.success.main,
    "--w-rjv-curlybraces-color": theme.palette.grey[300],
    "--w-rjv-colon-color": theme.palette.grey[300],
    "--w-rjv-brackets-color": theme.palette.grey[300],
    "--w-rjv-ellipsis-color": theme.palette.warning.main,
    "--w-rjv-quotes-color": theme.palette.primary.main,
    "--w-rjv-quotes-string-color": theme.palette.warning.light,
    "--w-rjv-type-string-color": theme.palette.warning.light,
    "--w-rjv-type-int-color": theme.palette.info.light,
    "--w-rjv-type-float-color": theme.palette.info.light,
    "--w-rjv-type-bigint-color": theme.palette.info.light,
    "--w-rjv-type-boolean-color": theme.palette.info.main,
    "--w-rjv-type-date-color": theme.palette.info.light,
    "--w-rjv-type-url-color": theme.palette.info.dark,
    "--w-rjv-type-null-color": theme.palette.info.main,
    "--w-rjv-type-nan-color": theme.palette.error.main,
    "--w-rjv-type-undefined-color": theme.palette.info.main
}));
export default JsonDisplay;