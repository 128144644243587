import localforage from "localforage";
import axios from "axios";
import cachios from "cachios";
import ApiException from "../context/ApiException";

class Api {
    static CACHE_DRIVER = {
        AUTO: 'AUTO',
        NOCACHE: 'NOCACHE',
        WEBSQL: 'WEBSQL',
        INDEXEDDB: 'INDEXEDDB',
        LOCALSTORAGE: 'LOCALSTORAGE'
    };

    noCache = false;
    dataCache = null;
    gateway = null;

    constructor(baseUrl,
                cacheDriver = Api.CACHE_DRIVER.AUTO,
                protectedKeyFields = ['password']) {

        const dataCacheConfig = {
            name        : baseUrl,
            storeName   : 'DataCache'
        }

        let noCache = false;
        switch(cacheDriver) {
            case Api.CACHE_DRIVER.WEBSQL:
                dataCacheConfig.driver = localforage.WEBSQL;
                break;
            case Api.CACHE_DRIVER.INDEXEDDB:
                dataCacheConfig.driver = localforage.INDEXEDDB;
                break;
            case Api.CACHE_DRIVER.LOCALSTORAGE:
                dataCacheConfig.driver = localforage.LOCALSTORAGE;
                break;
            case Api.CACHE_DRIVER.NOCACHE:
                noCache = true;
                break;
            case Api.CACHE_DRIVER.AUTO:
            default:
                dataCacheConfig.driver = [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE];
                break;
        }

        const dataCache = localforage.createInstance(dataCacheConfig);
        if(noCache) {
            console.log(`Api responses will not be cached.`);
        } else {
            dataCache.ready().then(function () {
                console.log(`Api Data Cache Driver: ${dataCache.driver()} Name: ${dataCacheConfig.name}, StoreName: ${dataCacheConfig.storeName}`);
            }).catch(function (e) {
                noCache = true;
                console.log(e);
            });
        }

        const api = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });

        const cachiosCacheConfig = {
                get: async (key) => {
                    return await dataCache.getItem(key);
                },
                set: async (key, value, ttl) => {
                    await dataCache.setItem(key, {
                        value: value,
                        expiresAt: new Date().getTime() + (ttl * 1000)
                    });
                },
                delete: async (key) => {
                    await dataCache.removeItem(key);
                }
            };

        const sanitizedKeyGen = (config) => {
            const { method, url, data, params, headers } = config;
            const sanitizedData = data ? { ...data } : {};
            protectedKeyFields.forEach(field => {
                for (const key in data) {
                    if (key.toLowerCase() === field.toLowerCase()) {
                        delete data[key];
                    }
                }
            });
            return JSON.stringify({ method, url, data: sanitizedData, params, headers });
        }

        this.noCache = noCache;
        this.dataCache = dataCache;
        this.gateway = cachios.create(api, {key: sanitizedKeyGen, cache: cachiosCacheConfig});
    }

    async applyCacheVersion(requiredVersion) {
        if(requiredVersion && !this.noCache) {
            console.log(`Applying Api Data Cache version ${requiredVersion}.`);
            const foundCacheVersion = await this.dataCache.getItem('__version__');
            if(!foundCacheVersion || foundCacheVersion < requiredVersion ) {
                console.log(`Api Data Cache version ${foundCacheVersion} is expired. Clearing Api Data Cache data and applying __version__:${requiredVersion}.`);
                await this.clearDataCache();
                await this.dataCache.setItem('__version__', requiredVersion);
            }
        }
    }

    async executeNoCacheRequest(request) {
        try {
            const response = await this.gateway.axiosInstance.request(request);
            return response;
        } catch(e) {
            const apiEx = new ApiException(e);
            console.error(apiEx);
            throw apiEx;
        }
    }

    async executeRequest(request) {
        if(this.noCache) {
            return await this.executeNoCacheRequest(request);
        } else {
            try {
                const response = await this.gateway.request(request);
                return response;
            } catch(e) {
                const apiEx = new ApiException(e);
                console.error(apiEx);
                throw apiEx;
            }
        }
    }

    async clearDataCache(key = null) {
        if(key) {
            await this.gateway.cache.delete(key);
        } else {
            for(const key of this.gateway.cache.keys()) {
                await this.gateway.cache.delete(key);
            }
        }
    }
}
export default Api;