import React from 'react';
import { Button, styled } from '@mui/material';

const SubtleButton = styled((props) => {
    const defaultProps = {
        variant: "text",
    };
    props = {...defaultProps, ...props};
    return (
        <Button {...props} />
    );
})
(({ theme }) => ({
    color: theme.palette.grey[500],
    textDecoration: "underline"
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default SubtleButton;