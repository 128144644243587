import React from 'react';
import { styled } from '@mui/material';
import DataValue from './DataValue';

const CurrencyValue = styled(({ children, ...props }) => {
    return (
        <DataValue {...props}>{`$${children?.toFixed(2)}`}</DataValue>
    );
})(({ theme }) => ({
}));
export default CurrencyValue;