import {styled} from "@mui/material";

const WindowCentered = styled('div')
(({ theme }) => ({
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
export default WindowCentered;
