import React, {useEffect} from 'react';
import { useUserContext } from '../base/context/UserContext';
import {Navigate} from "react-router-dom";
import {useApiContext} from "../base/context/ApiContext";
import {AppPath} from "../AppRouter";

const Logout = () => {
    const { logout } = useUserContext();
    const {api, callApi} = useApiContext();

    useEffect(() => {
        try {
            callApi(api.logout);
            logout();
        } catch (e) {
            // nothing to do here, just log out.
            console.error(e);
        }
    }, [api, callApi, logout]);

    return <Navigate to={AppPath.Login} replace />;
};

export default Logout;