import {BrowserRouter, Route, Routes, useSearchParams} from "react-router-dom";
import ProtectedRoutes from "./base/context/ProtectedRoutes";
import NavLayout from "./layout/NavLayout";
import Home from "./page/Home";
import About from "./page/About";
import Logout from "./page/Logout";
import RawLayout from "./layout/RawLayout";
import Login from "./page/Login";
import Error from "./page/Error";
import ShipmentHistory from "./page/ShipmentHistory";
import RequestPasswordReset from "./page/RequestPasswordReset";
import SetCredentials from "./page/SetCredentials";

export const AppPath = {
    Home: '/',
    ApiRoot: '/api',
    Login: '/login',
    Logout: '/logout',
    RequestPasswordReset: '/password-reset-request',
    ActivateAccount: '/activate-account',
    ResetPassword: '/reset-password',
    About: '/about',
    ShipmentHistory: '/shipment-history'
}

function AppRouter() {
    const isLoggedIn = (userContext) => {
        return userContext && userContext.id > 0;
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedRoutes isLoggedInFunc={isLoggedIn} />}>
                    <Route element={<NavLayout />}>
                        <Route path={AppPath.Home} element={<Home />} />
                        <Route path={AppPath.ShipmentHistory} element={<ShipmentHistory />} />
                        <Route path={AppPath.About} element={<About />} />
                    </Route>
                </Route>
                <Route element={<RawLayout />}>
                    <Route path={AppPath.Login} element={<Login />} />
                    <Route path={AppPath.RequestPasswordReset} element={<RequestPasswordReset />} />
                    <Route path={AppPath.ActivateAccount} element={<SetCredentials />} />
                    <Route path={AppPath.ResetPassword} element={<SetCredentials isReset={true} />} />
                    <Route path='*' element={<Error />} />
                </Route>
                <Route path={AppPath.Logout} element={<Logout />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRouter;