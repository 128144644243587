import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useApiContext} from "../base/context/ApiContext";
import HeadingText from "../base/typography/HeadingText";
import SubtleButton from "../base/button/SubtleButton";
import {AppPath} from "../AppRouter";
import * as Yup from "yup";
import {useFormik} from "formik";
import SecretTextInput from "../base/input/SecretTextInput";
import SubmitButton from "../base/button/SubmitButton";
import Paragraph from "../base/typography/Paragraph";

const SetCredentials = ({...props}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {api, callApi} = useApiContext();
    const navigate = useNavigate();

    const [isReset, setIsReset] = useState(props.isReset ?? false);
    const [isValidInvitation, setIsValidInvitation] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        const f = async () => {
            const token = searchParams.get('i');
            const userId = searchParams.get('id');
            const result = await callApi(api.testInvitationToken, userId, token);
            setIsValidInvitation(result);
        };
        f();
    }, [searchParams, api, callApi]);

    const handlePasswordReset = async (userId, token, newPassword) => {
        await callApi(api.resetForgottenPassword, userId, token, newPassword);
        setIsCompleted(true);
    }

    const initialValues = {password: 'mypassword2', password2: 'mypassword2'};

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Required'),
        password2: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                const token = searchParams.get('i');
                const userId = searchParams.get('id');
                await handlePasswordReset(userId, token, values.password);
            } catch (e) {
                console.error(e);
            } finally {
                setSubmitting(false);
            }
        }
    });

    return (
        <Grid container item xs={12} sm={10} md={8} lg={6}>
            {
                isCompleted &&
                <Grid container direction={"column"} justifyContent={"center"}>
                    <Paragraph textAlign={"center"}>Account credentials updated successfully!</Paragraph>
                    <SubtleButton onClick={() => navigate(AppPath.Login)}>Return to log in</SubtleButton>
                </Grid>
            }
            {
                !isCompleted && (isValidInvitation
                    ? <Grid container justifyContent={"center"}>
                        <HeadingText level={1} width={"100%"} textAlign={"center"}>
                            {(isReset) ? 'Reset Password' : 'Set Password' }
                        </HeadingText>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container>
                                <Grid container>
                                    <SecretTextInput
                                        id="password"
                                        name="password"
                                        label="Password"
                                        formik={formik}
                                    />
                                </Grid>
                                <Grid container>
                                    <SecretTextInput
                                        id="password2"
                                        name="password2"
                                        label="Confirm Password"
                                        formik={formik}
                                    />
                                </Grid>
                                <Grid container justifyContent={'space-between'}>
                                    <SubmitButton disabled={formik.isSubmitting}>Set Password</SubmitButton>
                                    <SubtleButton onClick={() => navigate(AppPath.Login)}>Cancel</SubtleButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    : <Grid container direction="column" justifyContent={"center"}>
                        <Paragraph textAlign={"center"}>The link information is not valid.</Paragraph>
                        {
                            isReset
                                ? <SubtleButton onClick={() => navigate(AppPath.RequestPasswordReset)}>Create new reset password link</SubtleButton>
                                : <Paragraph>Please contact your broker to obtain a new invitation link.</Paragraph>
                        }
                    </Grid>)
            }
        </Grid>
    );
};
export default SetCredentials;
