import React from 'react';
import { styled } from '@mui/material';
import InlineText from "./InlineText";

const DataLabel = styled((props) => {

    const defaultProps = {
        fontWeight: 'bold'
    };

    props = { ...defaultProps, ...props };

    return (
        <InlineText {...props} />
    );
})(({ theme }) => ({
    marginRight: theme.spacing(1)
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default DataLabel;