import {Card as MuiCard ,styled} from '@mui/material';

const Card = styled((props) => {
    const defaultProps = {

    };

    props = {...defaultProps, ...props};

    return (
        <MuiCard {...props} />
    );
})(({ theme }) => ({
    width: '100%',
    margin: theme.spacing(2)
}));

export default Card;