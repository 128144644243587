import localforage from "localforage";

class PageCache {
    static CACHE_DRIVER = {
        AUTO: 'AUTO',
        NOCACHE: 'NOCACHE',
        WEBSQL: 'WEBSQL',
        INDEXEDDB: 'INDEXEDDB',
        LOCALSTORAGE: 'LOCALSTORAGE'
    };

    store = null;
    noCache = false;

    constructor(name, cacheDriver = PageCache.CACHE_DRIVER.AUTO, localforageConfig = {}) {

        const dataCacheConfig = {
            name        : name,
            storeName   : 'PageCache',
            ...localforageConfig
        }

        switch(cacheDriver) {
            case PageCache.CACHE_DRIVER.WEBSQL:
                dataCacheConfig.driver = localforage.WEBSQL;
                break;
            case PageCache.CACHE_DRIVER.INDEXEDDB:
                dataCacheConfig.driver = localforage.INDEXEDDB;
                break;
            case PageCache.CACHE_DRIVER.LOCALSTORAGE:
                dataCacheConfig.driver = localforage.LOCALSTORAGE;
                break;
            case PageCache.CACHE_DRIVER.NOCACHE:
                this.noCache = true;
                break;
            case PageCache.CACHE_DRIVER.AUTO:
            default:
                dataCacheConfig.driver = [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE];
                break;
        }

        if(this.noCache) {
            this.store = {
                getItem: async (key) => {return undefined},
                setItem: async (key, value) => {},
                removeItem: async (key) => {},
                clear: async () => {}
            }
        } else {
            const store = localforage.createInstance(dataCacheConfig);
            store.ready().then(function () {
                console.log(`Page Cache Driver: ${store.driver()} Name: ${dataCacheConfig.name}, StoreName: ${dataCacheConfig.storeName}`);
            }).catch(function (e) {
                console.log(e);
            });
            this.store = store;
        }
    }

    async applyCacheVersion(requiredVersion) {
        if(requiredVersion) {
            console.log(`Applying Page Cache version ${requiredVersion}.`);
            const foundCacheVersion = await this.store.getItem('__version__');
            if(!foundCacheVersion || foundCacheVersion < requiredVersion ) {
                console.log(`Page Cache version ${foundCacheVersion} is expired. Clearing Page Cache and applying __version__:${requiredVersion}.`);
                await this.clearCache();
                await this.store.setItem('__version__', requiredVersion);
            }
        }
    }

    async clearCache(keys = null) {
        if (keys) {
            if(!Array.isArray(keys)) {keys = [keys];}
            for(const key of keys) {
                await this.store.removeItem(key);
            }
        } else {
            await this.store.clear();
        }
    }
}
export default PageCache;