import React from 'react';
import {styled} from '@mui/material';
import TextInput from './TextInput';

const SecretTextInput = styled((props) => {
    const defaultProps = {
        variant: "filled",
        type: "password",
        fullWidth: true
    };
    props = {...defaultProps, ...props};
    return (
        <TextInput {...props} />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default SecretTextInput;