import HeadingText from "../base/typography/HeadingText";
import ActionButton from "../base/button/ActionButton";
import React from "react";
import {useApiContext} from "../base/context/ApiContext";
import {Grid} from "@mui/material";

const Home = () => {
    const {api, callApi} = useApiContext();

    const onThrow = async (code) => {
        await callApi(api.throwError, code);
    }

    return (
        <>
            <Grid container>
                <Grid container>
                    <HeadingText width={"100%"} level={1}>Home Page</HeadingText>
                </Grid>
                <Grid container>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(400)}>Throw 400</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(401)}>Throw 401</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(403)}>Throw 403</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(404)}>Throw 404</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(422)}>Throw 422</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(503)}>Throw 503</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(500)}>Throw 500a</ActionButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1.5}>
                        <ActionButton onClick={() => onThrow(999)}>Throw 500b</ActionButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Home;
