import React from 'react';
import SubmitButton from "../base/button/SubmitButton";
import TextInput from "../base/input/TextInput";
import SecretTextInput from "../base/input/SecretTextInput";
import {useFormik} from "formik";
import {Grid} from "@mui/material";
import * as Yup from "yup";
import {useUserContext} from "../base/context/UserContext";
import {useNavigate} from "react-router-dom";
import {useApiContext} from "../base/context/ApiContext";
import {AppPath} from "../AppRouter";
import SubtleButton from "../base/button/SubtleButton";

const LoginForm = () => {
    const {login} = useUserContext();
    const {api, callApi} = useApiContext();
    let navigate = useNavigate();

    const goToPasswordReset = () => {
        navigate(AppPath.RequestPasswordReset);
    }

    const handleLogin = async (values) => {
        const userData = await callApi(api.login, values.username, values.password);
        // Perform login operation and set user data
        login(userData);
        const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
        navigate(redirectUrl ? `/${redirectUrl.replace(/^\//, '')}` : AppPath.Home);
    };

    const initialValues = {username: '', password: ''};

    const validationSchema = Yup.object({
        username: Yup.string()
            .email('Use your email address')
            .required('Required'),
        password: Yup.string()
            .required('Required'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                await handleLogin(values);
            } catch (e) {
                console.error(e);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid container>
                    <TextInput
                        id="username"
                        name="username"
                        label="Email"
                        autoComplete={'username email'}
                        formik={formik}
                    />
                </Grid>
                <Grid container>
                    <SecretTextInput
                        id="password"
                        name="password"
                        label="Password"
                        autoComplete={'current-password'}
                        formik={formik}
                    />
                </Grid>
                <Grid container justifyContent={'space-between'}>
                    <SubmitButton disabled={formik.isSubmitting}>Log In</SubmitButton>
                    <SubtleButton onClick={goToPasswordReset}>Forgot password</SubtleButton>
                </Grid>
            </Grid>
        </form>
    );
}
export default LoginForm;
