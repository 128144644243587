import React from 'react';
import {styled, TextField} from '@mui/material';

const TextInput = styled((props) => {
    const defaultProps = {
        variant: "filled",
        fullWidth: true
    };

    if(props.formik) {
        const key = props.formikKey ?? props.name ?? props.id;
        if(!key) {
            throw new Error('Form field must have an id or name.');
        }

        defaultProps.value = props.formik.values[key];
        defaultProps.onChange = props.formik.handleChange;
        defaultProps.error = props.formik.touched[key] && Boolean(props.formik.errors[key]);
        defaultProps.helperText = props.formik.touched[key] ? props.formik.errors[key] || props.helperText : '';
    }

    props = {...defaultProps, ...props};
    return (
        <TextField {...props} />
    );
})
(({ theme }) => ({
    // custom styles go here.
    // use theme values as much as possible. (ex. color: theme.palette.primary.main )
}));

export default TextInput;