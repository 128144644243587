import WindowCentered from "../layout/WindowCentered";
import {CircularProgress} from "@mui/material";
import WindowOverlay from "../layout/WindowOverlay";
import React from "react";

const LoadingOverlay = () => {
    return (
        <WindowOverlay>
            <WindowCentered>
                <CircularProgress size={100} />
            </WindowCentered>
        </WindowOverlay>
    );
}
export default LoadingOverlay;